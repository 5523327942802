import React from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "purecss";
import { ParallaxProvider } from "react-scroll-parallax";
import "./responsive.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function initialiseFirebase() {
  // todo: run only in production
  const app = initializeApp({
    apiKey: "AIzaSyCLYurEXnMGdqa5lU5Oyt5yIu1OTP5C-YU",
    authDomain: "dev-gem-app.firebaseapp.com",
    databaseURL: "https://dev-gem-app.firebaseio.com",
    projectId: "dev-gem-app",
    storageBucket: "dev-gem-app.appspot.com",
    messagingSenderId: "734004907348",
    appId: "1:734004907348:web:d38585322a4e791f448cc1",
    measurementId: "G-VWSTQML7YJ",
  });

  getAnalytics(app);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ParallaxProvider>
      <App />
    </ParallaxProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// initialise firebase
initialiseFirebase();
