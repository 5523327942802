import "./App.css";

function App() {
  return (
    <div className="App">
      <main>
        <div className="a6-container">
          <div className="glass-morphism-overlay">
            <p><strike>STUCK IN TRAFFIC.</strike> <strike>AROUND THE CORNER.</strike> <strike>JUST GOTTA FIND PARKING.</strike> <strike>SHOULD</strike> <span>BE</span> <strike>THERE IN A JIFFY.</strike> <strike>DEALING WITH A MINOR DELAY.</strike> <strike>QUICK DETOUR - ALMOST</strike>
              <span style={{marginLeft: 15}}>THERE</span>. <strike>RUNNING A BIT LATE.</strike> <strike>HOLD ON I’M AROUND THE BLOCK.</strike> <strike>WAITING FOR THE LIGHT TO TURN GREEN.</strike> <strike>NAVIGATING
                TRAFFIC.</strike> <strike>SORRY I’M LATE.</strike> <strike>I’LL BE THERE</strike> <span>IN</span> <strike>A FLASH.</strike> <strike>5 MORE MINUTES.</strike> <strike>JUST GOTTA CROSS THE BRIGDE.</strike> <strike>JUST LEFT THE HOUSE.</strike> <strike>SORRY, I’M LATE.</strike> <strike>I’M ON MY WAY - SHOULD</strike>
              <strike>BE THERE SOON-ISH.</strike> <span>5 MINUTES</span> <strike>AWAY</strike>. <strike>HANG TIGHT.</strike> <strike>THERE IN A FLASH.</strike> <strike>I’LL BE THERE SHORTLY.</strike> <strike>ON MY WAY.</strike> <strike>A FEW
                BLOCKS AWAY.</strike><strike>JUST ARRIVED.</strike> <strike>WHERE ARE YOU?</strike></p>
            <p>BY HERMENEGILDO ISAIAS</p>
          </div>
          </div>
      </main>
    </div>
);
}

export default App;
